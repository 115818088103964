export default {
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      subcatalog: "",
      warehouse: "",
      numberBoxes: "",
      unitPrice: "",
      unitMeasure: "",
      productAvailableTime: "",
      preparationTime: "",
      lotNumber: "",
      transportationTemp: "",
      storageTemp: "",
      itemsSubcategories: [],
      itemsUnitMeasure: [],
      texts: "",
      articleNameEn: "",
      articleNameSp: "",
      articleDescriptionEn: "",
      articleDescriptionSp: "",
      menu: false,
      model: null,
      articleDetailArr: [],
      sProductId:"",
    };
  },
  beforeMount() {
    this.texts = FILE.vendorTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();

  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit("setDialogDetailArticle", {
        active: false,
        arr: []
      });
      this.subcatalog = "";
      this.warehouse = "";
      this.numberBoxes = "";
      this.unitPrice = "";
      this.unitMeasure = "";
      this.productAvailableTime = "";
      this.preparationTime = "";
      this.lotNumber = "";
      this.transportationTemp = "";
      this.storageTemp = "";
      this.itemsSubcategories = [];
      this.itemsUnitMeasure = [];
      this.articleNameEn = "";
      this.articleNameSp = "";
      this.articleDescriptionEn = "";
      this.articleDescriptionSp = "";
    },
    addArticle() {
      this.bLoading = true;

      const params = {
        sSubcategoryId: this.subcatalog,
        sVendorWarehouseId: null,
        dMinAmountAllowed: this.numberBoxes,
        dUnitPrice: this.unitPrice,
        sMeasurementUnitId: this.unitMeasure,
        tProductAvailableTime: this.productAvailableTime,
        sLotId: this.lotNumber,
        dTransportationTemperature: this.transportationTemp,
        dStorageTemperature: this.storageTemp,
        aTranslations: [
          {
            sLang: "sp",
            sName: this.articleNameSp,
            sDescription: this.articleDescriptionSp,
          },
          {
            sLang: "en",
            sName: this.articleNameEn,
            sDescription: this.articleDescriptionEn,
          },
        ],
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/products`, params, config)
        .then((response) => {
          this.bLoading = false;

          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    articleDetailById() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products/${this.sProductId}`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          aImagesSizes: 'sm',        
        },
      })
        .then((response) => {
          this.articleDetailArr = response.data.results;

        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
  },
  computed: {
    dialogDetailArticle() {
      return this.$store.state.dialogDetailArticle;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm() {
      return (
        this.subcatalog !== "" &&
        this.warehouse !== "" &&
        this.numberBoxes !== "" &&
        this.unitPrice !== "" &&
        this.unitMeasure !== "" &&
        this.productAvailableTime !== "" &&
        this.productAvailableTime !== null &&
        this.preparationTime !== "" &&
        this.lotNumber !== "" &&
        this.transportationTemp !== "" &&
        this.storageTemp !== "" &&
        this.articleNameEn !== "" &&
        this.articleNameSp !== "" &&
        this.articleDescriptionEn !== "" &&
        this.articleDescriptionSp !== ""
      );
    },
  },
  watch: {
    dialogDetailArticle: function () {
      if (this.dialogDetailArticle) {
        this.texts = FILE.vendorTexts[this.selectLanguage];
        this.sProductId = this.$store.state.arrDetailArticle.sProductId;
        this.articleDetailById();
   
      }
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
    },
  },
};